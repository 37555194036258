import { ReactElement } from "react";
import { Field, Hint, Input, Label } from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { Trans } from "@lingui/macro";

import { Field as Config, FieldArrayProps } from "../config/types";
import { BusinessDetailsConfig } from "../../business-details/types";

interface TaxJurisdictionFieldProps extends FieldArrayProps {
  countryCode: string;
  labelElement?: ReactElement;
}

const OnlinePresenceField: React.FC<TaxJurisdictionFieldProps> = ({
  defaultValue,
  labelElement,
}) => {
  const { register, getValues } = useFormContext<BusinessDetailsConfig>();

  const { creditor_type: creditorType } = getValues();

  return (
    <Field>
      <Label htmlFor="online_presence_url">
        {labelElement ?? (
          <Trans id="setup.business-details.online-presence">
            Social media (optional)
          </Trans>
        )}
      </Label>
      <Hint>
        <Trans id="setup.business-details.online-presence-hint">
          Link to the {creditorType} Facebook, Instagram or LinkedIn page.
        </Trans>
      </Hint>
      <Input
        {...register("online_presence_url")}
        name="online_presence_url"
        id="online_presence_url"
        className="fs-exclude"
        defaultValue={defaultValue}
      />
    </Field>
  );
};

export const config: Config = {
  name: "online_presence_url",
  displayName: (
    <Trans id="setup.business-details.online-presence">
      Social media (optional)
    </Trans>
  ),
  component: OnlinePresenceField,
};
export default OnlinePresenceField;
