import { Box, Interpose, Space } from "@gocardless/flux-react";
import { renderFields } from "src/components/routes/Setup/common/config/utils";
import {
  Field as FieldType,
  FieldAttributes,
} from "src/components/routes/Setup/common/config/types";

import { PartnershipAddressInput } from "../common/components/Address/AddressInput";

import { BusinessDetailsConfig } from "./types";

interface PartnershipDetailsFormProps {
  fields: FieldType[];
  values: BusinessDetailsConfig;
}

const PartnershipDetailsForm = ({
  values,
  fields,
}: PartnershipDetailsFormProps) => {
  const fieldAttributes: FieldAttributes = {
    geo: {},
    creditor_type: {},
    partnership_type: {},
    legal_name: {
      props: {
        defaultValue: values.legal_name,
      },
    },
    trading_name: {},
    partnership_number: {},
    registered_address: {
      component: PartnershipAddressInput,
      props: {
        address: values,
        countryCode: values.geo,
      },
    },
    online_presence_url: {},
  };

  return (
    <Box>
      <Interpose node={<Space v={2} />}>
        {renderFields(fields, fieldAttributes)}
      </Interpose>
    </Box>
  );
};

export default PartnershipDetailsForm;
