import { Trans } from "@lingui/macro";

import * as Fields from "../fields";
import * as SEPA from "../SEPA/company";

// same as SEPA config with addition of TaxJurisidiction field
export const BusinessDetails = [
  [Fields.Country, Fields.CreditorType],
  {
    name: "companyDetails",
    displayName: "Company Details",
    component: [
      Fields.LegalName,
      Fields.CompanyTradingName,
      Fields.CompanyNumber,
      {
        name: "registered_address",
        displayName: (
          <Trans id="setup.business-details.registered-address">
            Registered address
          </Trans>
        ),
        component: [
          Fields.CompanyAddressLineOne,
          Fields.CompanyAddressLineTwo,
          Fields.CompanyAddressLineThree,
          Fields.CompanyCity,
          Fields.CompanyPostalCode,
        ],
      },
      Fields.FRTaxJurisdiction,
      Fields.OnlinePresence,
    ],
  },
  Fields.FRVATNumber,
];

export const BusinessCategory = SEPA.BusinessCategory;

export const BusinessDirectors = SEPA.BusinessDirectors;

export const BusinessOwners = SEPA.BusinessOwners;
