import * as Fields from "../fields";
import * as Global from "../GLOBAL/individual";

/**
 * US Individual Business Details
 * includes additional fields so
 * all are defined here
 */
export const BusinessDetails = [
  Fields.Country,
  Fields.ConsolidatedCreditorType,
  Fields.IndividualTradingName,
  Fields.OnlinePresence,
];

/**
 * US Individual About You
 * includes additional fields so
 * all are defined here
 */
export const AboutYou = [
  {
    name: "personDetails",
    displayName: "Person Details",
    component: [
      Fields.PersonGivenName,
      Fields.PersonFamilyName,
      Fields.PersonDateOfBirth,
    ],
  },
  {
    name: "individualNumber",
    displayName: "Individual Number",
    component: [Fields.PersonSocialSecurity, Fields.PersonTaxpayerIdNumber],
  },
  {
    name: "personAddress",
    displayName: "Person Address",
    component: [
      Fields.PersonFlatNumber,
      Fields.PersonBuildingNumber,
      Fields.PersonBuildingName,
      Fields.PersonStreet,
      Fields.PersonCity,
      Fields.PersonRegion,
      Fields.PersonPostalCode,
    ],
  },
];

/**
 * GB Business Category is the same
 * as the global configuration
 */
export const BusinessCategory = [...Global.BusinessCategory];
