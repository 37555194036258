import { useRequiredActionList } from "@gocardless/api/dashboard/required-action";
import {
  CreditorsVerificationStatus,
  RequiredActionsStatus,
} from "@gocardless/api/dashboard/types";
import { ColorPreset, Glyph, Icon } from "@gocardless/flux-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useVerificationStatus } from "src/common/hooks/useVerificationStatus";
import { useSegmentForSetup } from "src/components/routes/Setup/common/hooks/useSegmentForSetup";
import { TrackingEvent } from "src/common/trackingEvents";

import {
  InReviewIcon,
  SuccessfulIcon,
} from "../../common/RequiredActionStatusIcon";

interface PendingStatusIconProps {
  depositConfirmedAt: Date;
  verificationId: string;
  convertIsInReview: () => void;
}

export const spinnerWaitingTime = 30; // show spinner for 30s

const PendingSpinnerIcon = (
  <Icon
    name={Glyph.Spinner}
    color={ColorPreset.DataOnLight_02}
    size="24px"
    verticalAlign="middle"
    data-testid="spinner-pending-icon"
  />
);

const timeElapsedGreaterThan10s = (depositConfirmedAt: Date) => {
  const depositConfirmedAtMoment = moment(depositConfirmedAt);
  const currentMoment = moment(new Date());

  const timeElapsed = currentMoment.diff(depositConfirmedAtMoment, "seconds");

  return timeElapsed > spinnerWaitingTime;
};

export const PendingStatusIcon = ({
  depositConfirmedAt,
  verificationId,
  convertIsInReview,
}: PendingStatusIconProps) => {
  const { sendEvent } = useSegmentForSetup();
  const { status: creditorVerificationStatus } = useVerificationStatus();
  const { data: requiredActionsList, mutate } = useRequiredActionList();

  const requiredAction = requiredActionsList?.required_actions?.find(
    (action) => action.id === verificationId
  );
  const status = requiredAction?.status;

  const [verificationStatus, setVerificationStatus] = useState<
    RequiredActionsStatus | undefined
  >(status);
  const [refresh, setRefresh] = useState(false);

  const hasDocumentsToUpload =
    creditorVerificationStatus !== CreditorsVerificationStatus.InReview;

  // Refresh in 10 secs
  useEffect(() => {
    const interval = setInterval(() => {
      mutate();

      if (status) {
        setVerificationStatus(status);
      }
      setRefresh((prev) => !prev);
    }, spinnerWaitingTime * 1000);

    return () => clearInterval(interval);
  }, [mutate, status, refresh]);

  const hasTimeElapsed = timeElapsedGreaterThan10s(depositConfirmedAt);

  const canUpdateStatus =
    (hasTimeElapsed && verificationStatus === RequiredActionsStatus.Pending) ||
    (verificationStatus !== RequiredActionsStatus.Successful &&
      verificationStatus !== RequiredActionsStatus.Pending);

  useEffect(() => {
    // If status isn't successful or pending, or status is pending but more than 10 secs has elapsed
    if (canUpdateStatus) {
      convertIsInReview();
    }
  }, [canUpdateStatus, convertIsInReview]);

  // display InReviewIcon if merchant has documents to upload
  if (hasDocumentsToUpload) {
    convertIsInReview();
    return InReviewIcon;
  }

  // If the document status is InReview and 10s have not elapsed
  if (verificationStatus === RequiredActionsStatus.Pending && !hasTimeElapsed) {
    sendEvent(TrackingEvent.VERIFICATION_PENNY_TEST_SPINNER_VIEWED);
    return PendingSpinnerIcon;
  }

  // If the verification status is Successful
  if (verificationStatus === RequiredActionsStatus.Successful) {
    sendEvent(TrackingEvent.VERIFICATION_PENNY_TEST_SUCCESS_ICON_VIEWED);
    return SuccessfulIcon;
  }
  return InReviewIcon;
};
