import { onfidoSdkTokenCreate } from "@gocardless/api/dashboard/onfido-sdk-token";
import { Color, FontWeight, useTheme } from "@gocardless/flux-react";
import { useAttachDocuments } from "src/common/hooks/requiredActions";
import { useUserLanguage } from "src/technical-integrations/i18n";
import { TrackingEvent } from "src/common/trackingEvents";
import {
  AcceptedDocuments,
  PERSON_IDENTITY_DOCUMENTS,
} from "src/components/routes/Setup/common/constants/AcceptedDocuments";
import { ONFIDO_CONTAINER_ID } from "src/components/routes/SetupUser/Verify/consts";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import Sentry from "src/technical-integrations/sentry/sentry";
import { OnfidoApi } from "onfido-sdk-ui";

export const useOnfido = (
  onfidoToken: string,
  onfido: OnfidoApi,
  creditorId: string,
  verificationId: string,
  onSuccessfulUpload: () => Promise<void>,
  onOnfidoError: () => void
) => {
  const { sendEvent } = useSegment();
  const language = useUserLanguage();
  const { theme } = useTheme();
  const attachDocuments = useAttachDocuments();

  const documentTypes = PERSON_IDENTITY_DOCUMENTS.reduce(
    (obj, documentType) => ({ ...obj, [documentType]: true }),
    {} as Record<AcceptedDocuments, boolean>
  );

  const initOnfido = () => {
    const sdk = onfido.init({
      token: onfidoToken,
      containerId: ONFIDO_CONTAINER_ID,
      language: language,
      steps: [
        {
          type: "document",
          options: {
            documentTypes,
          },
        },
      ],
      customUI: {
        // fonts
        fontFamilyTitle:
          'Haffer, "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontFamilySubtitle:
          'Haffer, "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontFamilyBody:
          'Haffer, "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontWeightTitle: FontWeight.SemiBold,
        fontWeightSubtitle: FontWeight.SemiBold,
        fontWeightBody: FontWeight.SemiBold,

        // borders
        borderRadiusSurfaceModal: "15px",
        borderRadiusButton: "25px",
        borderWidthSurfaceModal: "0",

        // colors
        colorBackgroundButtonPrimary: theme.color(Color.Greystone_1400),
        colorIcon: theme.color(Color.Greystone_1400),
        colorBorderLinkUnderline: theme.color(Color.Greystone_1400),
        colorBackgroundButtonPrimaryActive: theme.color(Color.Greystone_1400),
        colorBorderDocTypeButton: theme.color(Color.Greystone_400),
        colorBackgroundLinkActive: "",
        colorBackgroundButtonIconActive: "",

        // hovers colors
        colorBackgroundButtonPrimaryHover: theme.color(Color.Greystone_1300),
        colorBorderButtonPrimaryHover: theme.color(Color.Greystone_1300),
        colorBorderButtonSecondaryHover: theme.color(Color.Greystone_1300),
        colorBorderDocTypeButtonHover: theme.color(Color.Greystone_1300),
        colorContentLinkTextHover: theme.color(Color.Greystone_1300),
        colorBackgroundLinkHover: "",
        colorBackgroundAlertInfoLinkHover: theme.color(Color.Greystone_1300),
        colorBackgroundAlertErrorLinkHover: theme.color(Color.Greystone_1300),
        colorBackgroundButtonIconHover: "",
      },
      onComplete: async () => {
        sendEvent(
          TrackingEvent.VERIFICATION_ADDITIONAL_DETAILS_ONFIDO_ID_CHECK_SUBMITTED
        );
        await sdk.tearDown();
        await attachDocuments(creditorId, verificationId, []);
        await onSuccessfulUpload();
      },
      onError: (error) => {
        Sentry.captureMessage(
          `Onfido UploadDocument failed for creditor ${creditorId} with '${error.message}'`
        );

        onOnfidoError();
      },
    });
  };

  return {
    initOnfido,
  };
};

export const getOnfidoToken = (verificationId: string) =>
  onfidoSdkTokenCreate({
    links: { verification: verificationId },
  })
    .then((response) => response?.onfido_sdk_tokens?.token)
    .catch(() => undefined);
