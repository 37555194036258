import {
  Button,
  ButtonVariant,
  Form,
  Interpose,
  Space,
} from "@gocardless/flux-react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useEffect, useState } from "react";
import omit from "lodash/omit";
import pick from "lodash/pick";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import FormError from "src/components/ui/form-error/FormError";
import PageTitle, {
  PageTitleVariant,
} from "src/components/routes/Setup/common/components/PageTitle";
import {
  FieldAttributes,
  SetupPages,
} from "src/components/routes/Setup/common/config/types";
import { renderFields } from "src/components/routes/Setup/common/config/utils";
import {
  CreditorDetailResource,
  CreditorType,
} from "@gocardless/api/dashboard/types";
import { useOrganisation } from "src/queries/organisation";
import { scrollToErrors } from "src/components/routes/Setup/common/helpers/scrollToErrors";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

import { useConfig } from "../common/config/useConfig";
import PersonDetail from "../common/components/PersonDetail";
import { IndividualPersonType } from "../about-you/useAboutYou";
import { AboutYouPersonAddressInput } from "../common/components/Address/AddressInput";
import { AddressConfig } from "../common/components/Address/AddressSearch";
import { IntroSubmitButton } from "../../Intro/IntroSubmitButton";

import CompanyBusinessDetailsForm from "./CompanyBusinessDetailsForm";
import CharityBusinessDetailsForm, {
  isCharityRestricted,
} from "./CharityBusinessDetailsForm";
import { BusinessDetailsConfig } from "./types";
import PartnershipDetailsForm from "./PartnershipDetailsForm";
import TrustDetailsForm from "./TrustDetailsForm";

export interface BusinessDetailsFormProps {
  businessDetails: BusinessDetailsConfig;
  isPaymentProvider?: boolean;
  loading: boolean;
  setLoading: (data: boolean) => void;
  submitBusinessDetails: (
    businessDetails: BusinessDetailsConfig,
    registeredBusinessSearchId: null | string,
    registeredBusinessSearchProvider: null | string,
    enterCompanyDetailsManually: boolean,
    variant?: BusinessDetailsFormVariant
  ) => void;
  variant: BusinessDetailsFormVariant;
  apiErrors?: string[];
  person?: IndividualPersonType;
  usVerificationImprovementExperimentEnabled?: boolean;
  creditorDetails?: CreditorDetailResource | undefined;
}

export enum BusinessDetailsFormVariant {
  Intro = "intro",
  Setup = "setup",
}

const BusinessDetailsForm = ({
  businessDetails,
  isPaymentProvider,
  loading,
  setLoading,
  submitBusinessDetails,
  variant,
  apiErrors,
  person,
  usVerificationImprovementExperimentEnabled = false,
  creditorDetails,
}: BusinessDetailsFormProps): JSX.Element => {
  const { i18n } = useLingui();

  const { isFlagEnabled: isVerificationsUnsupportedEntityType } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.VERIFICATIONS_UNSUPPORTED_ENTITY_TYPE,
    });

  // we can't render new US config for payment providers
  // because they need to have a separate company type select
  // this has to be fixed before new US config experiment is enabled for everyone
  const altConfig =
    usVerificationImprovementExperimentEnabled && !isPaymentProvider;

  const { getFieldsByCreditorTypeAndGeo } = useConfig(
    SetupPages.BUSINESS_DETAILS,
    altConfig
  );
  const form = useForm<BusinessDetailsConfig>({
    mode: "onChange",
    defaultValues: businessDetails,
    shouldUnregister: true,
  });

  const {
    formState: { errors, isSubmitted, isSubmitting },
    handleSubmit,
    setError,
  } = form;

  const doSubmit: SubmitHandler<BusinessDetailsConfig> = (data) => {
    setLoading(true);
    try {
      submitBusinessDetails(
        data,
        registeredBusinessSearchId,
        registeredBusinessSearchProvider,
        enterCompanyDetailsManually,
        variant
      );
    } catch (e) {
      scrollToErrors(errors);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      apiErrors?.includes("Entity number") &&
      usVerificationImprovementExperimentEnabled
    ) {
      setError("company_number", {
        message: i18n._(
          t({
            message: "Please enter a valid Taxpayer Identification Number",
          })
        ),
      });
    }
  }, [apiErrors, i18n, setError, usVerificationImprovementExperimentEnabled]);

  const [registeredBusinessSearchId, setRegisteredBusinessSearchId] = useState<
    string | null
  >(creditorDetails?.detail?.registered_business_search_id ?? null);

  const [
    registeredBusinessSearchProvider,
    setRegisteredBusinessSearchProvider,
  ] = useState<string | null>(
    creditorDetails?.detail?.registered_business_search_provider ?? null
  );

  const [enterCompanyDetailsManually, setEnterCompanyDetailsManually] =
    useState(false);

  const [companySearchCompleted, setCompanySearchCompleted] = useState(
    !!businessDetails.company_number
  );
  const filterErrors = omit(errors, "creditor_type");
  const creditorType = form.watch(
    "creditor_type",
    businessDetails.creditor_type
  );

  const fields = getFieldsByCreditorTypeAndGeo(
    creditorType,
    businessDetails.geo
  );

  const { fx_payout_currency } = useOrganisation() ?? {};
  const charityRestricted = !isCharityRestricted(
    fx_payout_currency,
    businessDetails?.scheme_identifiers
  );
  const charityNotEnabled =
    creditorType === CreditorType.Charity && charityRestricted;

  const isUnsupported =
    !!fields.find((f) => f.name === "unsupported_creditor_type") ||
    charityNotEnabled;

  const address: AddressConfig = pick(person, [
    "flat_number",
    "building_number",
    "building_name",
    "street",
    "city",
    "region",
    "postal_code",
    "country_code",
  ]);

  const fieldAttributes: FieldAttributes = {
    geo: {
      canRender: variant === BusinessDetailsFormVariant.Setup,
    },
    consolidatedCreditorType: {
      canRender: !isPaymentProvider,
    },
    creditor_type: {
      canRender: !isPaymentProvider,
    },
    company_type: {},
    company_number: {},
    trading_name: {
      canRender: variant === BusinessDetailsFormVariant.Setup,
    },
    entity_number: {},
    tax_jurisdiction: {
      props: { countryCode: businessDetails.geo },
      canRender: variant === BusinessDetailsFormVariant.Setup,
    },
    tax_number: {
      canRender:
        (creditorType !== CreditorType.Company || companySearchCompleted) &&
        variant === BusinessDetailsFormVariant.Setup,
      props: {
        taxNumber: businessDetails.tax_number,
      },
    },
    companyDetails: {
      component: CompanyBusinessDetailsForm,
      props: {
        values: businessDetails,
        enterCompanyDetailsManually,
        setEnterCompanyDetailsManually,
        companySearchCompleted,
        setCompanySearchCompleted,
        setRegisteredBusinessSearchId,
        setRegisteredBusinessSearchProvider,
        usVerificationImprovementExperimentEnabled:
          usVerificationImprovementExperimentEnabled,
      },
    },
    charityDetails: {
      component: CharityBusinessDetailsForm,
      props: {
        values: businessDetails,
        usVerificationImprovementExperimentEnabled:
          usVerificationImprovementExperimentEnabled,
      },
    },
    partnershipDetails: {
      component: PartnershipDetailsForm,
      props: { values: businessDetails },
    },
    trustDetails: {
      component: TrustDetailsForm,
      props: { values: businessDetails },
    },
    unsupported_creditor_type: {
      props: { creditorType, geo: businessDetails.geo },
    },
    personDetails: {
      component: PersonDetail,
      props: {
        defaultValue: person,
        countryCode: businessDetails.geo,
        showPlaceOfBirth: false,
      },
      canRender: variant === BusinessDetailsFormVariant.Intro,
    },
    personAddress: {
      component: AboutYouPersonAddressInput,
      props: {
        address: address,
        countryCode: businessDetails.geo,
      },
      canRender: variant === BusinessDetailsFormVariant.Intro,
    },
  };

  return (
    <FormProvider {...form}>
      <Form
        onSubmit={handleSubmit(doSubmit)}
        data-testid="business-details"
        className="fs-unmask"
      >
        {isSubmitted && <FormError errors={filterErrors} />}
        <PageTitle
          title={i18n._(
            t({
              id: "setup.business-details-title",
              message: "Tell us about your business",
            })
          )}
          description={i18n._(
            t({
              id: "setup.business-details.description",
              message:
                "We require information about your business so we can meet our regulation requirements and enable you to start accepting payments. These checks won’t affect your credit score.",
            })
          )}
          variant={
            variant === BusinessDetailsFormVariant.Intro
              ? PageTitleVariant.Intro
              : PageTitleVariant.Setup
          }
        />
        <Interpose node={<Space v={2} />}>
          {renderFields(fields, fieldAttributes)}
        </Interpose>

        {variant === BusinessDetailsFormVariant.Setup ? (
          isVerificationsUnsupportedEntityType && isUnsupported ? (
            <></>
          ) : (
            <>
              <Space v={3} />
              <Button
                type="submit"
                variant={ButtonVariant.PrimaryOnLight}
                disabled={isUnsupported || isSubmitting || loading}
              >
                <Trans id="Continue">Continue</Trans>
              </Button>
            </>
          )
        ) : (
          <IntroSubmitButton
            onClick={handleSubmit(doSubmit)}
            isSubmitting={isSubmitting || loading}
          />
        )}
      </Form>
    </FormProvider>
  );
};

export default BusinessDetailsForm;
