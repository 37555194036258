import {
  Button,
  ButtonLayout,
  ButtonVariant,
  ColorPreset,
  Glyph,
  Icon,
  Space,
} from "@gocardless/flux-react";
import { FC, MouseEventHandler } from "react";
import { ToTranslate } from "src/components/i18n";

interface IntroSubmitButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  isSubmitting?: boolean;
}

export const IntroSubmitButton: FC<IntroSubmitButtonProps> = ({
  onClick,
  disabled = false,
  isSubmitting = false,
}) => (
  <>
    <Space v={3} />
    <Button
      type="button"
      variant={ButtonVariant.PrimaryOnLight}
      layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
      disabled={disabled || isSubmitting}
      onClick={onClick}
    >
      {isSubmitting ? (
        <Icon
          css={{
            display: "flex",
          }}
          color={ColorPreset.IconOnLight_01}
          name={Glyph.Spinner}
          size="22px"
        />
      ) : (
        <ToTranslate>Continue</ToTranslate>
      )}
    </Button>
  </>
);
