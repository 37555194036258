import {
  RequiredActionsStatus,
  RequiredVerificationStatus,
} from "@gocardless/api/dashboard/types";
import {
  OrganisationType,
  useOrganisationWithType,
} from "src/queries/organisationType";
import { useSegmentForSetup } from "src/components/routes/Setup/common/hooks/useSegmentForSetup";
import { useEffect } from "react";
import { TrackingEvent } from "src/common/trackingEvents";

import { OptimisedVerificationStatus } from "../optimised-verification-status";
import { RewardFeeDiscountDialogSetupVariant } from "../../SetupPayments/home/CollectionsEnabled/RewardFeeDiscountDialogSetupVariant";

import VerificationStatus from "./VerificationStatus";
import { useAccountStatus } from "./useAccountStatus";

export const Statuses = {
  Successful: [RequiredActionsStatus.Successful],
  Unsuccessful: [
    RequiredActionsStatus.Unsuccessful,
    RequiredActionsStatus.CouldNotVerify,
  ],
  Pending: [
    RequiredActionsStatus.Pending,
    RequiredActionsStatus.PendingResponse,
    RequiredVerificationStatus.Pending,
  ],
  InReview: [
    RequiredActionsStatus.InReview,
    RequiredActionsStatus.Processing,
    RequiredActionsStatus.InAutomaticReview,
    RequiredVerificationStatus.InReview,
  ],
};

interface AccountStatusProps {
  inRedesignVerificationStatusAndFileUploadExperiment?: boolean;
}

const AccountStatus: React.FC<AccountStatusProps> = ({
  inRedesignVerificationStatusAndFileUploadExperiment = false,
}) => {
  const { sendEvent } = useSegmentForSetup();
  const { status, partner, mutate: mutateAccountStatus } = useAccountStatus();
  const { organisationType } = useOrganisationWithType();
  const isPaymentProvider =
    organisationType === OrganisationType.PaymentProvider;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailRedirect = params.get("emailRedirect");
    if (emailRedirect === "true") {
      sendEvent(TrackingEvent.VERIFICATION_PENNY_TEST_REDIRECTED_FROM_EMAIL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RewardFeeDiscountDialogSetupVariant />
      {inRedesignVerificationStatusAndFileUploadExperiment ? (
        <OptimisedVerificationStatus
          status={status}
          partner={partner}
          mutateAccountStatus={mutateAccountStatus}
        />
      ) : (
        <VerificationStatus
          status={status}
          partner={partner}
          isPaymentProvider={isPaymentProvider}
        />
      )}
    </>
  );
};

export default AccountStatus;
