import {
  Box,
  Button,
  ButtonSize,
  ButtonVariant,
  Dialog,
  H2,
  JustifyContent,
  P,
  PlainButton,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useState } from "react";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { ZendeskLink } from "src/components/ui/ZendeskLink/ZendeskLink";
import { ZENDESK_SUPPORT_FORMS } from "src/technical-integrations/zendesk/constants";
import BankAccountDetails from "src/components/routes/Setup/verify-bank/BankAccountDetails";
import { RequiredActionsDetails } from "@gocardless/api/dashboard/types";

interface PennyTestDialogProps {
  details?: RequiredActionsDetails;
  sourceComponent: string;
}

export const PennyTestDialog: React.FC<PennyTestDialogProps> = ({
  details,
  sourceComponent,
}) => {
  const { sendEvent } = useSegment();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDialog = () => setIsDialogOpen((previous) => !previous);
  const handleDialogOpen = () => {
    sendEvent(TrackingEvent.VERIFICATION_PENNY_TEST_HAVING_TROUBLE_CLICKED, {
      source: sourceComponent,
    });
    toggleDialog();
  };

  if (!details) return null;

  const contactUsLink = (
    <ZendeskLink
      formId={ZENDESK_SUPPORT_FORMS.verification}
      variant={ButtonVariant.InlineUnderlined}
      data-tracking-label="Verifications request support"
    >
      <Trans>contact us</Trans>
    </ZendeskLink>
  );

  return (
    <Box className="fs-unmask" layout="inline-block">
      <PlainButton decoration="underline" onClick={handleDialogOpen}>
        <Trans>Having trouble?</Trans>
      </PlainButton>
      <Dialog
        className="fs-unmask"
        open={isDialogOpen}
        aria-labelledby="penny-test-dialog"
        closeAction={{
          label: <Trans>Close</Trans>,
          onClose: toggleDialog,
        }}
        header={
          <H2 preset={TypePreset.Heading_02}>
            <Trans>Having trouble?</Trans>
          </H2>
        }
        footer={
          <Box layout="flex" justifyContent={JustifyContent.FlexEnd}>
            <Button
              variant={ButtonVariant.PrimaryOnLight}
              size={ButtonSize.Sm}
              onClick={toggleDialog}
            >
              <Trans>Go back</Trans>
            </Button>
          </Box>
        }
      >
        <Box spaceBelow={1}>
          <P preset={TypePreset.Body_02}>
            {details.account_number_ending ? (
              <Trans>
                To verify your bank account ending{" "}
                <Text className="fs-mask">{details.account_number_ending}</Text>
                , we require that you make a payment to GoCardless using the
                details:
              </Trans>
            ) : (
              <Trans>
                To verify your bank account, we require that you make a payment
                to GoCardless using the details:
              </Trans>
            )}
          </P>
          <Space v={[1, 1.5]} />
          <BankAccountDetails details={details} copyableDetails />
        </Box>
        <P preset={TypePreset.Body_02}>
          <Trans>
            If you have already made the payment and we have not verified your
            bank account within 3 days, please {contactUsLink} for support.
          </Trans>
        </P>
      </Dialog>
    </Box>
  );
};
