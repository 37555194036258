import { Trans } from "@lingui/macro";

import * as Fields from "../fields";
import * as Global from "../GLOBAL/company";

/**
 * US Company Business Details
 * includes additional fields so
 * all are defined here
 */
export const BusinessDetails = [
  [Fields.Country, Fields.CreditorType, Fields.CompanyType],
  {
    name: "companyDetails",
    displayName: "Company Details",
    component: [
      Fields.LegalName,
      Fields.CompanyTradingName,
      Fields.CompanyNumber,
      {
        name: "registered_address",
        displayName: (
          <Trans id="setup.business-details.registered-address">
            Registered address
          </Trans>
        ),
        component: [
          Fields.CompanyAddressLineOne,
          Fields.CompanyAddressLineTwo,
          Fields.CompanyAddressLineThree,
          Fields.CompanyCity,
          Fields.CompanyRegion,
          Fields.CompanyPostalCode,
        ],
      },
      Fields.OnlinePresence,
    ],
  },
];

/**
 * US Business Category is the same
 * as the global configuration
 */
export const BusinessCategory = [...Global.BusinessCategory];

/**
 * US Company Business Directos
 * includes additional fields so
 * all are defined here
 */
export const BusinessDirectors = [
  {
    name: "directorDetails",
    displayName: "Director Details",
    component: [
      Fields.DirectorGivenName,
      Fields.DirectorFamilyName,
      Fields.DirectorDateOfBirth,
    ],
  },
  Fields.DirectorSelect,
  {
    name: "individualNumber",
    displayName: "Individual Number",
    component: [
      Fields.DirectorSocialSecurity,
      Fields.DirectorPassportNumber,
      Fields.DirectorPassportCountry,
    ],
  },
  Fields.DirectorCountryCode,
  {
    name: "directorAddress",
    displayName: "Director Address",
    component: [
      Fields.DirectorFlatNumber,
      Fields.DirectorBuildingNumber,
      Fields.DirectorBuildingName,
      Fields.DirectorStreet,
      Fields.DirectorCity,
      Fields.DirectorRegion,
      Fields.DirectorPostalCode,
    ],
  },
];

/**
 * US Company Business Owners
 * includes additional fields so
 * all are defined here
 */
export const BusinessOwners = [
  [
    {
      name: "controlProngMember",
      displayName: "Control Prong Member",
      component: [
        {
          name: "controlProngDetails",
          displayName: "Control Prong Details",
          component: [
            Fields.ControlGivenName,
            Fields.ControlFamilyName,
            Fields.ControlDateOfBirth,
          ],
        },
        {
          name: "individualNumber",
          displayName: "Individual Number",
          component: [
            Fields.ControlSocialSecurity,
            Fields.ControlPassportNumber,
            Fields.ControlPassportCountry,
          ],
        },
        Fields.ControlCountryCode,
        {
          name: "controlProngAddress",
          displayName: "Control Prong Address",
          component: [
            Fields.ControlFlatNumber,
            Fields.ControlBuildingNumber,
            Fields.ControlBuildingName,
            Fields.ControlStreet,
            Fields.ControlCity,
            Fields.ControlRegion,
            Fields.ControlPostalCode,
          ],
        },
      ],
    },
    Fields.OwnerAvailable,
    {
      name: "ownerMember",
      displayName: "Business Owner",
      component: [
        {
          name: "ownerDetails",
          displayName: "Owner Details",
          component: [
            Fields.OwnerGivenName,
            Fields.OwnerFamilyName,
            Fields.OwnerDateOfBirth,
          ],
        },
        {
          name: "individualNumber",
          displayName: "Individual Number",
          component: [
            Fields.OwnerSocialSecurity,
            Fields.OwnerPassportNumber,
            Fields.OwnerPassportCountry,
          ],
        },
        Fields.OwnerCountryCode,
        {
          name: "ownerAddress",
          displayName: "Owner Address",
          component: [
            Fields.OwnerFlatNumber,
            Fields.OwnerBuildingNumber,
            Fields.OwnerBuildingName,
            Fields.OwnerStreet,
            Fields.OwnerCity,
            Fields.OwnerRegion,
            Fields.OwnerPostalCode,
          ],
        },
      ],
    },
  ],
];
