import { Route, useRouterQuery } from "src/common/routing";
import { useOrganisationRequestPackage } from "@gocardless/api/dashboard/organisation";
import { useAccessToken } from "src/common/authorisation";
import { useOrganisation } from "src/queries/organisation";
import { usePrimaryCreditor } from "src/queries/creditor";
import { useCreditorDetailSelf } from "@gocardless/api/dashboard/creditor-detail";
import { useToastNotification } from "src/hooks/useToastNotification";
import { useRouter } from "next/router";

import useToast from "../../common/hooks/useToast";
import { UseSetupPage } from "../../routing/types";
import { PackageType } from "../../common/types/packageType";
import { MerchantOnboardingSetupEvents } from "../../common/constants/MerchantOnboardingSetupEvents";
import { useSegmentForSetup } from "../../common/hooks/useSegmentForSetup";

export interface UsePackageSelection extends UseSetupPage {
  requestPackage: (pkg: string) => void;
}

const displayNameToPackageStates: Record<string, PackageType[]> = {
  standard: [PackageType.Standard],
  plus: [PackageType.StandardPendingPlus, PackageType.Plus],
  pro: [
    PackageType.StandardPendingPro,
    PackageType.PlusPendingPro,
    PackageType.Pro,
  ],
  enterprise: [
    PackageType.StandardPendingEnterprise,
    PackageType.PlusPendingEnterprise,
    PackageType.ProPendingEnterprise,
    PackageType.Enterprise,
    PackageType.Custom,
  ],
};

const isCurrentPackage = (curPkg: string, reqPkg: string) =>
  Object.entries(displayNameToPackageStates)
    .filter((i) => i.pop()?.includes(curPkg as PackageType))
    .pop()
    ?.join("") === reqPkg;

export const usePackageSelection = (): UsePackageSelection => {
  const router = useRouter();
  const { sendEvent } = useSegmentForSetup();
  const { onSuccess } = useToast();
  const { triggerErrorNotification } = useToastNotification();
  const [accessToken] = useAccessToken();

  const organisation = useOrganisation();
  const creditor = usePrimaryCreditor();
  const { data: creditorDetails } = useCreditorDetailSelf(creditor?.id || null);

  const [requestPackage] = useOrganisationRequestPackage(
    accessToken?.links?.organisation || "",
    {
      onError() {
        triggerErrorNotification({
          message: `To change your package please contact help@gocardless.com`,
        });
      },
      onSuccess: () => {
        onSuccess({ notify: true });
      },
    }
  );

  const skipPackageSelection = useRouterQuery("skip_package_selection").item;

  const completed =
    !!skipPackageSelection ||
    (!!creditorDetails?.creditor_details?.creditor_type &&
      !organisation?.cpv_enabled) ||
    !!organisation?.organisation_preferences?.merchant_has_requested_package ||
    !!localStorage.getItem(`gc.package.${organisation?.id}`);

  const skip =
    !!organisation?.links?.payment_provider ||
    organisation?.account_type === "sales_served";

  return {
    loaded: !!creditorDetails && !!organisation,
    completed,
    route: Route.PackageSelection,
    skip,
    requestPackage: async (pkg: string) => {
      localStorage.setItem(`gc.package.${organisation?.id}`, pkg);
      if (isCurrentPackage(organisation?.package_state as string, pkg)) {
        onSuccess({ notify: true });
      } else {
        await requestPackage({
          requested_package: pkg,
        });
      }
      sendEvent(MerchantOnboardingSetupEvents.PackageSelectionSelected, {
        page_version: "variant",
        package: pkg,
        page: router.pathname,
      });
    },
  };
};
