import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { CompanyUBOsUpload } from "src/components/routes/Setup/optimised-verification-status/required-actions/company-ubos/CompanyUBOsUpload";
import { RequiredAction } from "src/components/routes/Setup/optimised-verification-status/required-actions/RequiredAction";
import { RequiredActionCardProps } from "src/components/routes/Setup/optimised-verification-status/required-actions/RequiredActionCard";

export const CompanyUBOsCard = (props: RequiredActionCardProps) => {
  const { actionStatus, notificationEmail } = props;
  const months = 12;

  switch (actionStatus) {
    case RequiredActionsStatus.Pending:
      return (
        <RequiredAction
          header={<Trans>Verify your beneficial owner details</Trans>}
          description={
            <Trans>
              Please upload a copy of an acceptable document issued within the
              last {months} months that confirms all your company and beneficial
              owner details.
            </Trans>
          }
          body={<CompanyUBOsUpload {...props} />}
        />
      );
    case RequiredActionsStatus.PendingResponse:
      return (
        <RequiredAction
          header={<Trans>Verify your beneficial owner details</Trans>}
          description={
            <Trans>
              We&apos;ve received your document and our verification team has
              reached out to you at <b>{notificationEmail}</b> for further
              information.
            </Trans>
          }
        />
      );
    default:
      return null;
  }
};
