import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { Text } from "@gocardless/flux-react";
import { CountryCodes } from "src/common/country";

import { RequiredAction } from "../RequiredAction";
import { RequiredActionCardProps } from "../RequiredActionCard";

import { CompanyDocumentUpload } from "./CompanyDocumentUpload";

export const CompanyIdentityCard = (props: RequiredActionCardProps) => {
  const { entity, actionStatus, notificationEmail: nEmail } = props;
  const entityTitle = <Text className="fs-mask">{entity.name || ""}</Text>;
  const notificationEmail = <Text className="fs-mask">{nEmail}</Text>;

  const defaultMaxAge = 3;

  const maxAgeMap: Partial<{ [key in CountryCodes]: number }> = {
    [CountryCodes.US]: 12,
    [CountryCodes.ES]: 12,
  };

  const maximumAgeOfExtract = maxAgeMap[props.creditorGeo] || defaultMaxAge;

  const isEsCompany = props.creditorGeo === CountryCodes.ES;

  switch (actionStatus) {
    case RequiredActionsStatus.Pending:
      return (
        <RequiredAction
          header={<Trans>Verify details for {entityTitle}</Trans>}
          description={
            isEsCompany ? (
              <Trans>
                Please upload a copy of a company extract issued directly from
                the Registro Mercantil Central within the last{" "}
                {maximumAgeOfExtract} months.
              </Trans>
            ) : (
              <Trans>
                Please upload a copy of an extract from your official company
                registry dated within the last {maximumAgeOfExtract} months.
              </Trans>
            )
          }
          body={<CompanyDocumentUpload {...props} />}
        />
      );
    case RequiredActionsStatus.PendingResponse:
      return (
        <RequiredAction
          header={<Trans>Verify details for {entityTitle}</Trans>}
          description={
            <Trans>
              We&apos;ve received your document and our verification team has
              reached out to you at <b>{notificationEmail}</b> for further
              information.
            </Trans>
          }
        />
      );
    default:
      return null;
  }
};
